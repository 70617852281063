import * as React from 'react'

function MIA(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 120.34 120.34'
      fill='#64e8ba'
      {...props}
    >
      <g data-name='Layer 2'>
        <g data-name='Layer 1'>
          <text
            transform='translate(25.67 72.88)'
            style={{
              isolation: 'isolate'
            }}
            fontSize={38.61}
            fontFamily='Arial-BoldMT,Arial'
            fontWeight={700}
          >
            {'MI'}
            <tspan x={42.88} y={0} letterSpacing='-.04em'>
              {'A'}
            </tspan>
          </text>
          <path d='M60.17 8A52.17 52.17 0 118 60.17 52.22 52.22 0 0160.17 8m0-8a60.17 60.17 0 1060.17 60.17A60.17 60.17 0 0060.17 0z' />
        </g>
      </g>
    </svg>
  )
}

export default MIA
