import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 384.28 102.07'
      {...props}
    >
      <defs>
        <style>
          {'.prefix__cls-1{fill:#f890e7;stroke:#f890e7;stroke-miterlimit:10}'}
        </style>
      </defs>
      <g id='prefix__Layer_2' data-name='Layer 2'>
        <g id='prefix__Layer_1-2' data-name='Layer 1'>
          <g id='prefix__Layer_2-2' data-name='Layer 2'>
            <g id='prefix__Layer_1-2-2' data-name='Layer 1-2'>
              <path
                className='prefix__cls-1'
                d='M69.6 55.47c-2.55 6.32-10.19 10.6-15.9 10.6-6.52 0-10.09-4.48-10.09-10.5 0-5.3 5.4-15 11-21.6a24.52 24.52 0 01-8 1.83C39.27 52.1 27.86 66 14.92 66 5.6 66 .5 59.85.5 51.9a26.77 26.77 0 013.06-11.72C9 29.79 17 24.08 22.92 24.08a6.83 6.83 0 013.57.82 15 15 0 016 5.81c1.53 3.26-.1 6.93-2 10.9a32.28 32.28 0 00-1.83 4.18c-.74 2.14-1.16 2.68-3.7 1.32a6.51 6.51 0 01-3.57-5.91c0-1.53 1.32-4.89 2.34-7.23 1-2.14 2-4.18 1.53-4.59-1.32-1-7.85 5.2-12.13 13.86a27.31 27.31 0 00-3.06 11.31c0 4.28 1.73 7.13 4.69 7.34 10 .41 20.89-14.47 26.19-27a4.51 4.51 0 01-2.85-4.48c0-5.4 6.72-12.84 10.19-12.94 2.14 0 3 1.32 3 3.57a31 31 0 01-1.94 8.56 25.66 25.66 0 008.87-4.38c1.53-1.12 3.16-1.53 4.48-.1a10.63 10.63 0 012.8 6.93 6.22 6.22 0 01-1.73 4.08c-4.38 5.09-9.37 11.72-11 17.12a14.41 14.41 0 00-.77 4.59c0 2.55.82 4.08 2.34 4.18 3.46.31 9.27-6.22 12.33-13.25.92-2.14 2.45-2.34 3.46-.1.49 1.1.69 3.8-.53 6.8z'
              />
              <path
                className='prefix__cls-1'
                d='M106.5 54.47c-4.08 6.22-10.8 10-19.16 13.45-5.6 13.45-12.53 29.75-21.4 33.08-2.34 1-4.48 1-6.94-2.3a15.15 15.15 0 01-3-8.46 16.24 16.24 0 01.9-4.77c3.16-8.76 11.41-15 21.3-19l1.53-3.57a13.53 13.53 0 01-8.36 2.85c-5.1 0-7.34-3.87-7.34-8.15a21.48 21.48 0 011.32-7.13A113.38 113.38 0 0179 25.12c1.32-1.73 2.85-1.83 4-.2a9.24 9.24 0 011.94 4.89c0 2.55-1.22 5.5-3.57 9.27a86.84 86.84 0 00-7.54 14.47c-1.43 3.67-2.34 7.74.31 7.74s7.23-6 12.23-14.47c6.83-11.51 9.58-17.73 12-21.4 1.43-1.83 2.75-2 3.87-.92a6.53 6.53 0 011.94 5.2 14.59 14.59 0 01-1.83 6.83c-3 5.71-8.25 15.59-12.43 25.27a51.8 51.8 0 0014.78-13.55c1.32-1.83 2.85-2 3.26-.71a8.47 8.47 0 01-1.46 6.93zM65 84.82c-2.55 4.79-3.26 9-2.24 9.88.71.61 2.85-2.34 5.71-7.34 2.14-3.77 4.59-8.76 7-14.27A34.18 34.18 0 0065 84.82z'
              />
              <path
                className='prefix__cls-1'
                d='M137.56 50c-2.45 5.6-5.81 10.19-9.07 12.84 7.54-1.53 13.35-8.25 16.3-14.16 1.22-2.34 3.36-1.83 3.77.2a10.83 10.83 0 01-1.43 6.93C142.5 64 134 66.89 125.5 66.89c-8 0-13-2.75-15.39-8.25-4.48 8.36-8.87 17.12-13.11 27.1-.82 1.83-2 2.24-3.06 1a7.9 7.9 0 01-2-5.5 22 22 0 011.22-6.62c3-9.68 19.77-41.37 26.09-49.83 1.83-2.24 3.16-1.83 4-.71a6.22 6.22 0 011 3.77c2.55-1.73 5.5-3.06 8.25-3.06 6.11 0 9.07 5.5 9.07 10.7-.07 4.4-1.66 9.59-4.01 14.51zm-20.48-4c-3.16 6.52-2.45 13.86.2 13.86 3.16 0 7.74-5.6 11.92-14.06a32.19 32.19 0 003.67-13.3c0-1.83-.2-3.06-.71-3.26-1.83-.82-7.44 3.87-11.82 11.31z'
              />
              <path
                className='prefix__cls-1'
                d='M145.41 46.1a214.73 214.73 0 0110-20.38c-3.16-.41-5-1.53-5.81-2.75-1.43-2.14-.41-3.16 1.94-3.06a58 58 0 007.34-.2c3.16-5.5 5.3-8.86 7.64-12.23 1.53-2.24 2.75-2.14 3.87-.2a9.19 9.19 0 011.11 4.19 18 18 0 01-1.63 7 7.36 7.36 0 015.6 3.26c1 1.83.31 3.06-2.14 2.75a30.94 30.94 0 00-6.73.31c-3.87 6.62-9.68 16.71-13.25 24.46a19.38 19.38 0 00-1.85 7.66c0 3.06 1 5.2 2.75 5.2 4 0 9.27-6.42 12.84-14.06 1.12-2.45 3.46-2.65 4.18.1.51 2.14.1 3.67-1 6C168.84 57.21 162 66 153.46 66c-5.4 0-10.09-4.38-10.09-11.41a22.36 22.36 0 012.04-8.49z'
              />
              <path
                className='prefix__cls-1'
                d='M198.6 44.27c-2.14 8.46-8.66 18.44-16.41 21.09a11.17 11.17 0 01-3.67.61 12.4 12.4 0 01-10-5.2 14.33 14.33 0 01-2.85-8.86c0-4.48 1.63-9.48 4.28-14.27 4.59-8.15 11-13.25 17.73-13.25 2.95 0 4.79 1.12 6.73 3.77 2.85 3.36 5.71 4.08 4.79 12.74 2.65 0 2.85.92 2 1.94a4.28 4.28 0 01-2.6 1.43zm-22.52 16.5c2.65 0 6.73-3.77 10.5-10.7a34.56 34.56 0 003.36-7.34 13 13 0 01-6.22-8.26 32.78 32.78 0 00-5.81 7.64c-2.65 4.89-4.28 10.6-4.28 14.37.01 3.07.92 4.29 2.45 4.29zm16.92-28c0-1.53-.81-2.14-1.53-2.14s-1.53.81-1.53 2.45c0 1.83.51 4.48 1.63 5.81a17.11 17.11 0 001.43-6.14z'
              />
              <path
                className='prefix__cls-1'
                d='M221.05 23.79c3.67 0 5.91 1.83 6.83 4.48l1.94-3.16c1.22-2 3-2.14 4.18-.71 1.73 2 2.65 6.52 0 11.82-2.65 5.6-11.21 16.71-11.21 23.54 0 1.53.41 2.45 1.43 2.45 3.67 0 8.76-6.93 12.13-13.55 1.12-2 2-2.14 3.06 0a8.42 8.42 0 01-.41 6.62c-2.65 5.6-9.17 11-15.39 11-5.09 0-8.05-2.65-8.86-6.42-3 2.85-7.54 6-11.62 6-6 0-9.27-4.38-9.27-10.39 0-4.38 1.83-10.09 5.5-15.18 8.14-11.72 16.49-16.5 21.69-16.5zm-3.55 22.82c4-6.93 5.81-11.92 5.81-14.57 0-1.22-.2-1.94-1.22-1.94-2 0-9.07 6.42-14.27 14.77-3.06 4.79-4.82 10.19-4.82 13.45 0 1.83.41 3.06 1.43 3.06 3.37.01 8.07-6.21 13.07-14.77z'
              />
              <path
                className='prefix__cls-1'
                d='M268.41 30.61c1.53 3.36-.1 7.13-2 11.11a34.2 34.2 0 00-1.91 4.18c-.71 1.94-1.63 2.14-3.67 1.12a6.83 6.83 0 01-3.57-5.81c0-1.32.51-3.36 2.34-7.54 1-2 1.73-3.87 1.32-4.28-1.22-1-7.85 5.6-11.92 13.86a27.71 27.71 0 00-3.06 11.41c0 4.48 1.83 7.23 4.59 7.34 7.64.1 14.67-7.74 18.75-13.86.92-1.53 2.45-1.94 3.06-.2.51 1.32.51 4-1.32 6.83C267.15 60.88 258.89 65.88 251 66c-9.37.1-14.57-5.91-14.57-14.06a25.77 25.77 0 013.16-11.82c5.2-10.5 13.25-16 19.16-16 3.75-.03 8.03 3.44 9.66 6.49z'
              />
              <path
                className='prefix__cls-1'
                d='M301.5 50c-2.45 5.6-5.81 10.19-9.07 12.84 7.54-1.53 13.35-8.25 16.3-14.16 1.22-2.34 3.36-1.83 3.77.2a10.83 10.83 0 01-1.43 6.93C306.5 64 298 66.89 289.5 66.89c-8.05 0-13-2.75-15.39-8.25-4.48 8.36-8.86 17.12-13.11 27.1-.82 1.83-2 2.24-3.06 1a7.9 7.9 0 01-2-5.5 22 22 0 011.22-6.62c2.95-9.68 19.77-41.37 26.09-49.83 1.83-2.24 3.16-1.83 4-.71a6.22 6.22 0 011 3.77c2.55-1.73 5.5-3.06 8.25-3.06 6.11 0 9.07 5.5 9.07 10.7-.07 4.4-1.71 9.59-4.07 14.51zM281 46c-3.16 6.52-2.45 13.86.2 13.86 3.16 0 7.74-5.6 11.92-14.06a32.06 32.06 0 003.69-13.3c0-1.83-.2-3.06-.71-3.26-1.83-.82-7.44 3.87-11.82 11.31z'
              />
              <path
                className='prefix__cls-1'
                d='M309.37 46.1a214.73 214.73 0 0110-20.38c-3.16-.41-5-1.53-5.81-2.75-1.43-2.14-.41-3.16 1.94-3.06a58 58 0 007.34-.2c3.16-5.5 5.3-8.86 7.64-12.23 1.53-2.24 2.75-2.14 3.87-.2a9.2 9.2 0 011.15 4.19 18 18 0 01-1.63 7 7.36 7.36 0 015.6 3.26c1 1.83.31 3.06-2.14 2.75a30.94 30.94 0 00-6.73.31c-3.87 6.62-9.68 16.71-13.25 24.46a19.21 19.21 0 00-1.85 7.66c0 3.06 1 5.2 2.75 5.2 4 0 9.27-6.42 12.84-14.06 1.12-2.45 3.46-2.65 4.18.1.51 2.14.1 3.67-1 6C332.84 57.21 326 66 317.46 66c-5.4 0-10.09-4.38-10.09-11.41a22.12 22.12 0 012-8.49z'
              />
              <path
                className='prefix__cls-1'
                d='M360.5 53.47a16.76 16.76 0 00-1.83 6.53c0 1.53.41 2.24 1.32 2.24 3.57 0 8.56-6.73 12-13.45 1-1.94 2.34-1.83 3.26 0a8.47 8.47 0 01-.41 6.62c-2.75 5.5-9.07 10.8-15 10.8-5 0-8.36-1.73-9.17-6.22-3.16 3.26-7.13 5.91-11.62 5.91-5.91 0-9.17-4.38-9.17-10.39 0-4.28 1.83-9.88 5.4-15.08 8-11.51 16.41-16.3 21.7-16.3 3.46 0 5.71 1.43 6.52 4.08 5.09-9.58 11.72-21.09 15.08-25.88 1.83-2.55 3.26-2.34 4.28.1a10.44 10.44 0 01.92 4c0 2.75-1.32 6.72-4 11.51-5.87 11.14-14.53 25.41-19.28 35.53zm-1.83-23.13c-2.85-.1-9.58 6.11-15 14.67-3.06 4.79-4.67 9.88-4.67 13.25 0 1.83.41 3.16 1.53 3.16 3.26 0 8.15-5.71 12.74-14.57 5.48-10.53 7.31-16.44 5.38-16.54z'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
