import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

import Paper from '@mui/material/Paper'
import STX from '../../static/STX'
import BTC from '../../static/BTC'
import DOGE from '../../static/DOGE'
import MIA from '../../static/MIA'
import ETH from '../../static/ETH'
import LTC from '../../static/LTC'
import useMediaQuery from '@mui/material/useMediaQuery'

const Venue = ({ venue }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [coins, setCoins] = useState(null)

  useEffect(() => {
    const size = isSmallScreen ? 46 : 54

    const coins = [MIA, BTC, ETH, STX, DOGE, LTC].map((Icon, index) => {
      const isAccepted = venue.currencies.find((coin) => {
        let coinName
        switch (index) {
          case 0:
            coinName = 'MIA'
            break
          case 1:
            coinName = 'BTC'
            break
          case 2:
            coinName = 'ETH'
            break
          case 3:
            coinName = 'STX'
            break
          case 4:
            coinName = 'DOGE'
            break
          case 5:
            coinName = 'LTC'
            break
          default:
            break
        }

        return coin.name === coinName
      })

      if (isAccepted) {
        return (
          <Icon
            style={{
              height: size,
              width: size
            }}
          />
        )
      } else {
        return (
          <Icon
            style={{
              height: size,
              width: size,
              opacity: '0.1'
            }}
          />
        )
      }
    })

    setCoins(coins)
  }, [isSmallScreen, venue.currencies])

  return (
    <Paper elevation={4} sx={{ padding: '0.75rem', borderRadius: '0.75rem' }}>
      <Grid container justifyContent='space-between' alignItems='flex-start'>
        <Grid item xs={7}>
          <Typography
            variant='h5'
            component='div'
            sx={{
              fontWeight: 700,
              fontSize: { xs: '16px', sm: '20px', md: '24px' },
              color: '#f890e7'
            }}
          >
            <a href={venue.url} target='_blank' rel='noreferrer'>
              {venue.name}
            </a>
          </Typography>
          <Typography
            variant='subtitle2'
            component='div'
            sx={{
              maxWidth: '90%',
              fontSize: { xs: '12px', sm: '14px' },
              paddingBottom: '1rem'
            }}
          >
            {venue.note}
          </Typography>
          <Typography
            variant='subtitle2'
            component='div'
            sx={{ maxWidth: '137px', fontSize: { xs: '12px', sm: '14px' } }}
            gutterBottom
          >
            {venue.address}
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              maxWidth: '137px',
              textDecoration: 'underline',
              fontSize: { xs: '12px', sm: '14px' },
              pt: 1
            }}
            gutterBottom
          >
            <a href={venue.gMapLink} target='_blank' rel='noreferrer'>
              View Location
            </a>
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={5}
          sx={{
            padding: isSmallScreen ? '0.25rem' : '0.75rem',
            minHeight: '11rem'
          }}
        >
          <Grid item container spacing={2}>
            {coins &&
              coins.map((coin, index) => {
                return (
                  <Grid key={index} sm={4} xs={6} item>
                    {coin}
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Venue
