import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { handleFormData } from '../../utilities/sharedfunctions'

const ButtonFilter = ({
  category,
  setFilters,
  setQueryLimit,
  perPage,
  isActive
}) => {
  const handleClick = (category) => {
    handleFormData(setFilters, isActive, category)
    setQueryLimit(perPage)
  }

  return (
    <Box
      onClick={() => {
        handleClick(category)
      }}
    >
      {isActive ? (
        <Button
          disableElevation
          size='small'
          variant='contained'
          color='success'
          sx={{ borderRadius: '10px', width: '100%' }}
        >
          {category.name}
        </Button>
      ) : (
        <Button
          disableElevation
          size='small'
          variant='outlined'
          color='success'
          sx={{ borderRadius: '10px', width: '100%' }}
        >
          {category.name}
        </Button>
      )}
    </Box>
  )
}

export default ButtonFilter
