import { useState, forwardRef } from 'react'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { inputLabelClasses } from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { collection, addDoc } from 'firebase/firestore'
import { db } from '../../utilities/firebase'
import MuiAlert from '@mui/material/Alert'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Contact = () => {
  const [open, setOpen] = useState(false)

  const labelProps = {
    sx: {
      color: '#ffffff',
      [`&.${inputLabelClasses.shrink}`]: {
        color: '#ffffff'
      }
    }
  }

  const {
    handleSubmit,
    values,
    errors,
    touched,
    resetForm,
    handleBlur,
    setFieldValue
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNo: '',
      venueAddress: '',
      venueName: ''
    },
    validationSchema: yup.object({
      name: yup.string().required('First name is a required field'),
      email: yup
        .string()
        .email('Invalid email format')
        .required('Email is required field'),
      phoneNo: yup
        .string()
        .min(14, 'Phone number entered is not valid')
        .max(14, 'Phone number entered is not valid')
        .required('Phone number is a required field')
    }),
    onSubmit: async (values) => {
      const params = { ...values, createDate: new Date() }

      await addDoc(collection(db, 'contact'), {
        ...params
      })
      setOpen(true)
      resetForm()
      setTimeout(() => {
        setOpen(false)
      }, 6000)
    }
  })

  const removeCountryCode = (initialValue) => {
    if (initialValue.charAt(0) === '1') {
      return initialValue.replace(/[^a-zA-Z0-9+]/g, '').substr(1)
    } else if (initialValue.charAt(0) === '+') {
      return initialValue.replace(/[^a-zA-Z0-9+]/g, '').substr(2)
    } else {
      return initialValue.replace(/[^a-zA-Z0-9]/g, '')
    }
  }

  const formatValue = (value) => {
    if (value.length <= 3) {
      return value.replace(phoneRegExp, '$1')
    } else if (value.length <= 6) {
      return value.replace(phoneRegExp, '($1) $2')
    } else {
      return value.replace(phoneRegExp, '($1) $2-$3')
    }
  }

  const phoneRegExp = /^\(?([0-9]{0,3})\)?([0-9]{0,3})[\s-]?([0-9]{0,4})$/

  const handlePhoneChange = (e, setFieldValue) => {
    const initialValue = removeCountryCode(e.target.value)
    const simpleValue = initialValue.replace(/[^0-9.]/g, '').slice(0, 10)
    const formattedValue = formatValue(simpleValue)
    setFieldValue('phoneNo', formattedValue)
  }

  return (
    <>
      <Box sx={{ padding: '4rem 0' }}>
        <Container sx={{ maxWidth: '550px !important' }}>
          <Paper
            elevation={4}
            sx={{ padding: '2rem 1.5rem', borderRadius: '0.75rem' }}
          >
            <Typography
              variant='h5'
              gutterBottom
              sx={{ textAlign: 'center', mb: '1rem' }}
            >
              Add a Business
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{ mb: '1.5rem' }}
              >
                <TextField
                  value={values.name}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value)
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  name='name'
                  InputLabelProps={labelProps}
                  label='Name'
                  variant='outlined'
                />
              </Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{ mb: '1.5rem' }}
              >
                <TextField
                  value={values.phoneNo}
                  onChange={(e) => handlePhoneChange(e, setFieldValue)}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.phoneNo && Boolean(errors.phoneNo)}
                  helperText={touched.phoneNo && errors.phoneNo}
                  name='phoneNo'
                  InputLabelProps={labelProps}
                  label='Phone Number'
                  variant='outlined'
                />
                <TextField
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue('email', e.target.value)
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  name='email'
                  InputLabelProps={labelProps}
                  label='Email'
                  variant='outlined'
                />
              </Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{ mb: '1.5rem' }}
              >
                <TextField
                  value={values.venueName}
                  onChange={(e) => {
                    setFieldValue('venueName', e.target.value)
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.venueName && Boolean(errors.venueName)}
                  helperText={touched.venueName && errors.venueName}
                  name='venueName'
                  InputLabelProps={labelProps}
                  label='Business Name'
                  variant='outlined'
                />
                <TextField
                  value={values.venueAddress}
                  onChange={(e) => {
                    setFieldValue('venueAddress', e.target.value)
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.venueAddress && Boolean(errors.venueAddress)}
                  helperText={touched.venueAddress && errors.venueAddress}
                  name='venueAddress'
                  InputLabelProps={labelProps}
                  label='Business Address'
                  variant='outlined'
                />
              </Stack>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{ borderRadius: 0 }}
              >
                Submit
              </Button>
            </form>
          </Paper>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={6000}
      >
        <Alert severity='success' sx={{ width: '100%' }}>
          Thank You For Your Submission!
        </Alert>
      </Snackbar>
    </>
  )
}

export default Contact
