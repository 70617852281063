import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

export default function Header() {
  const isSmallScreen = useMediaQuery('(max-width:767px)')
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='static'
        sx={{ backgroundColor: '#030c1b', borderBottom: 'solid 1px #132542' }}
      >
        <Toolbar
          variant='dense'
          sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}
        >
          {isSmallScreen ? <MobileHeader /> : <DesktopHeader />}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
