import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 980,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    text: {
      primary: '#ffffff'
    },
    background: {
      default: '#12252b',
      paper: '#375b66'
    },
    success: {
      main: '#64e8ba'
    },
    primary: {
      main: '#388687'
    }
  }
})

export default theme
