function BTC(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={290.667}
      height={285.333}
      viewBox='0 0 218 214'
      fill='#64e8ba'
      {...props}
    >
      <path d='M96.5 3.7C53.7 9 19 39.8 7.9 82.3c-3.3 12.5-3.7 32.2-1 45.3 7.6 37 33.9 66.3 70.1 78.3 55.5 18.2 115-13.7 131.5-70.6 6.7-23.2 4.9-49.6-5-71-14.7-31.9-44-54.2-78.6-59.8-8.6-1.4-21-1.7-28.4-.8zm38 16.7c50.4 15.1 77.4 70.5 58.3 119.9-6.4 16.6-20.4 33.3-36.1 43.1-29.6 18.5-68.5 18.3-97-.5-40.3-26.4-53.2-81-28.8-121.8 12.9-21.5 34.4-36.9 59.6-42.5 11.2-2.5 32.5-1.6 44 1.8z' />
      <path d='M87 50.5V60H63v15h6c10.3 0 10-1 10 32.5 0 27.2-.1 28.6-2 30.5-1.5 1.5-3.3 2-6.9 2-4.8 0-4.8 0-5.4 3.7-.4 2.1-.9 5.3-1.3 7l-.6 3.3H87v20h13v-20h9v20h11.9l.3-9.8.3-9.7 6.5-.7c18.6-1.8 30.3-12.4 30.4-27.2 0-3.4-.5-7.7-1.2-9.7-1.6-4.7-6.6-9.7-12-12.1l-4.3-1.9 2.9-2.7c4.4-4.2 6.4-8.8 6.4-15.1 0-6.6-1.8-11.3-6.1-15.9-3.7-4-12-8-18.3-8.8l-4.8-.7V41h-12v19h-9v-4.3c0-2.3-.3-6.6-.6-9.5l-.7-5.2H87v9.5zm31 25.8c4.8 1.7 7.6 6.7 6.4 11.5-1.5 5.7-4.9 7.5-15.4 8l-9 .4V75l7.3.1c3.9 0 8.8.5 10.7 1.2zm6 37.5c5.3 2.6 8.3 7.7 7.7 13.1-1 9.4-5.9 12.4-20.9 12.9l-10.8.4V112h10.3c7.7 0 11 .4 13.7 1.8z' />
    </svg>
  )
}

export default BTC
