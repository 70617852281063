import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import IconButton from '@mui/material/IconButton'

const linkStyle = {
  cursor: 'pointer',
  color: '#ffffff !important',
  fontSize: '12px',
  p: '0 1rem'
}

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#030c1b',
        borderTop: 'solid 1px #132542',
        minHeight: 80,
        zIndex: '1000',
        p: '1rem'
      }}
    >
      <Grid container alignItems='center' justifyContent='center' spacing={4}>
        <Grid item>
          <IconButton
            size='medium'
            sx={{ color: '#ffffff' }}
            href='https://www.instagram.com/cryptoacptd/'
          >
            <InstagramIcon fontSize='inherit' />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            size='medium'
            sx={{ color: '#ffffff' }}
            href='https://twitter.com/cryptoacptd'
          >
            <TwitterIcon fontSize='inherit' />
          </IconButton>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems='center'
        justifyContent='center'
        spacing={2}
        sx={{ p: '1.25rem 1rem' }}
      >
        <Grid item>
          <a
            href='https://app.sigle.io/cryptoacptd.id.stx/'
            style={{
              color: '#ffffff',
              cursor: 'pointer',
              textDecoration: 'none',
              fontSize: '12px'
            }}
          >
            <Typography sx={linkStyle}>About</Typography>
          </a>
        </Grid>
        <Grid item>
          <Link
            to='/contact'
            style={{
              color: '#ffffff',
              cursor: 'pointer',
              textDecoration: 'none',
              fontSize: '12px'
            }}
          >
            <Typography sx={linkStyle}>Add a Business</Typography>
          </Link>
        </Grid>
        <Grid item>
          <Typography sx={linkStyle}>Terms</Typography>
        </Grid>
        <Grid item>
          <Typography sx={linkStyle}>Privacy Policy</Typography>
        </Grid>
      </Grid>
      <Typography
        variant='subtitle2'
        sx={{ color: '#ffffff', fontSize: '12px', textAlign: 'center' }}
      >
        Copyright © 2021 Company Name Inc., all rights reserved.{' '}
      </Typography>
    </Box>
  )
}

export default Footer
