import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyAt3WxoPAgJy3iLqMC5mbN2Ngl0saqRcxU',
  authDomain: 'cryptoacptd.firebaseapp.com',
  projectId: 'cryptoacptd',
  storageBucket: 'cryptoacptd.appspot.com',
  messagingSenderId: '442291990617',
  appId: '1:442291990617:web:40b3617c613ba387668148'
}

const firebase = initializeApp(config)
const db = getFirestore(firebase)

if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(db, 'localhost', 8080)
}

export { firebase, db }
