import * as React from 'react'

const ETH = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 120.34 120.34'
    fill='#64e8ba'
    {...props}
  >
    <g data-name='Layer 2'>
      <g data-name='Layer 1'>
        <path d='M60.17 8A52.17 52.17 0 1 1 8 60.17 52.22 52.22 0 0 1 60.17 8m0-8a60.17 60.17 0 1 0 60.17 60.17A60.17 60.17 0 0 0 60.17 0Z' />
        <path d='m60.17 44.02 27.54 16.55-27.54 16.56V44.02zM60.17 44.02 32.63 60.57l27.54 16.56V44.02z' />
        <path d='M60.17 44.02 32.63 60.57l27.54-46.19v29.64zM60.17 44.02l27.54 16.55-27.54-46.19v29.64zM60.17 82.79v23.16l27.54-39.71-27.54 16.55zM60.17 82.79 32.63 66.24l27.54 39.71V82.79z' />
      </g>
    </g>
  </svg>
)

export default ETH
