import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Logo from '../../static/Logo'

const DesktopHeader = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/', { replace: true })
  }

  return (
    <Container maxWidth='lg'>
      <Box
        sx={{
          flexGrow: 1,
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem'
        }}
      >
        <Logo
          style={{ width: '18rem', cursor: 'pointer' }}
          onClick={handleClick}
        />
      </Box>
    </Container>
  )
}

export default DesktopHeader
