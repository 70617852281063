import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Logo from '../../static/Logo'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  opacity: 0.95,
  border: 'none',
  outline: 'none'
}

export default function BasicModal({ setOpen, open }) {
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          pt='10rem'
          sx={style}
        >
          <Logo style={{ width: '18rem' }} />
          <Typography
            id='modal-modal-description'
            variant='h6'
            color='white'
            align='center'
            sx={{ mt: 4, mb: 4, maxWidth: '80%' }}
          >
            Get started finding businesses that accept crypto in Miami!
          </Typography>
          <Button
            onClick={handleClose}
            disableElevation
            variant='contained'
            color='secondary'
            sx={{ borderRadius: 2, width: '15rem', height: '3rem' }}
          >
            Search Now
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
