import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
// import Admin from './pages/Admin'
import Contact from './pages/Contact'
import Header from './components/Header'
import Footer from './components/Footer'
import { ThemeProvider } from '@mui/material/styles'
import Theme from './utilities/theme'
import './App.css'
import LogRocket from 'logrocket'

LogRocket.init('cryptoaccepted-llc/cryptoacptd')

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path='/admin' element={<Admin />} /> */}
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  )
}

export default App
