import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import CryptoFilter from '../../pages/Home/CryptoFilter'
import MobileButtonFilter from './MobileButtonFilter'
import { DataContext } from '../../state/data'
import Logo from '../../static/Logo'

const MobileHeader = () => {
  const {
    filters,
    setFilters,
    setQueryLimit,
    perPage,
    currencies,
    categories
  } = useContext(DataContext)
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (value) => {
    setDrawerOpen(value)
  }

  const handleClick = () => {
    navigate('/', { replace: true })
  }

  const clearFilters = () => {
    setFilters((filters) => {
      return filters.map((filter) => {
        return filter?.isActive
          ? { ...filter, isActive: !filter.isActive }
          : filter
      })
    })
    setQueryLimit(perPage)
    setDrawerOpen(false)
  }

  const renderDrawer = () => {
    return (
      <Stack
        direction='column'
        justifyContent='space-between'
        sx={{
          width: '260px',
          p: '1rem',
          backgroundColor: '#030c1b',
          height: '100%'
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '2rem'
            }}
          >
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>

          <Typography
            variant='subtitle1'
            gutterBottom
            component='div'
            sx={{ fontWeight: 700, mb: '1rem' }}
          >
            Cryptocurrency
          </Typography>
          <Grid
            sx={{ mb: '2rem' }}
            container
            spacing={{ xs: 2 }}
            columns={{ xs: 6, sm: 8, md: 12 }}
          >
            {currencies.map((currency, index) => {
              const foundCurrency = filters.find(
                (filter) => filter.id === currency.id
              )
              const isChecked = foundCurrency?.isActive
              return (
                <CryptoFilter
                  key={index}
                  setQueryLimit={setQueryLimit}
                  perPage={perPage}
                  isChecked={isChecked}
                  currency={currency}
                  setFilters={setFilters}
                />
              )
            })}
          </Grid>
          <Typography
            variant='subtitle1'
            gutterBottom
            component='div'
            sx={{ fontWeight: 700, mb: '1rem' }}
          >
            Categories
          </Typography>
          <Grid container spacing={1} columns={{ xs: 6, sm: 8, md: 12 }}>
            {categories.map((category, index) => {
              const foundCategory = filters.find(
                (filter) => filter.id === category.id
              )
              const isActive = foundCategory?.isActive
              return (
                <MobileButtonFilter
                  key={index}
                  isActive={isActive}
                  setQueryLimit={setQueryLimit}
                  perPage={perPage}
                  category={category}
                  setFilters={setFilters}
                />
              )
            })}
          </Grid>
        </Box>
        <Stack direction='row' spacing={2} sx={{ mb: '1.5rem' }}>
          <Button
            onClick={clearFilters}
            disableElevation
            variant='contained'
            size='small'
            sx={{ borderRadius: 2 }}
          >
            Clear Filters
          </Button>
          <Button
            onClick={() => toggleDrawer(false)}
            disableElevation
            variant='contained'
            size='small'
            sx={{ borderRadius: 2 }}
          >
            Save Filters
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      <Container maxWidth='lg'>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
        >
          <Grid item xs={10}>
            <Logo
              style={{ width: '12rem', cursor: 'pointer' }}
              onClick={handleClick}
            />
          </Grid>
          <Grid item xs={2} container justifyContent='flex-end'>
            <IconButton
              onClick={() => toggleDrawer(true)}
              edge='start'
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        {renderDrawer()}
      </Drawer>
    </>
  )
}

export default MobileHeader
