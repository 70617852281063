import React, { useState, createContext, useEffect } from 'react'
import {
  collection,
  getDocs,
  query,
  orderBy,
  getDoc,
  doc
} from 'firebase/firestore'
import { db } from '../utilities/firebase'
export const DataContext = createContext()

export const DataProvider = (props) => {
  const [filters, setFilters] = useState([])
  const [categories, setCategories] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [queryLimit, setQueryLimit] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [modalState, setModalState] = useState(true)

  const assignDbRef = (collectionName) => {
    switch (collectionName) {
      case 'currencies':
        return query(collection(db, collectionName), orderBy('rank'))
      case 'categories':
        return query(collection(db, collectionName), orderBy('name'))
      default:
        return null
    }
  }

  const fetchCollection = async (collectionName, dbRef) => {
    const querySnapshot = await getDocs(dbRef)
    const collectionData = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), type: collectionName, id: doc.id }
    })
    return collectionData
  }

  useEffect(() => {
    const initialData = async () => {
      const collections = ['categories', 'currencies']
      const collectionData = await Promise.all(
        collections.map(async (name) => {
          const dbRef = assignDbRef(name)
          return await fetchCollection(name, dbRef)
        })
      )
      setCategories(collectionData[0])
      setCurrencies(collectionData[1])
      const initialFilterData = collectionData.flat()
      setFilters(initialFilterData)
    }
    initialData()
  }, [])

  useEffect(() => {
    const getPageVariable = async () => {
      const docRef = doc(db, 'variables', 'results')
      const docSnap = await getDoc(docRef)
      const documentData = docSnap.data()
      const resultLimit = documentData?.perPage
      setQueryLimit(resultLimit)
      setPerPage(resultLimit)
    }
    getPageVariable()
  }, [])

  return (
    <DataContext.Provider
      value={{
        filters,
        setFilters,
        categories,
        currencies,
        queryLimit,
        setQueryLimit,
        perPage,
        setPerPage,
        modalState,
        setModalState
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}
