import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { handleFormData } from '../../utilities/sharedfunctions'

const MobileButtonFilter = ({
  category,
  setFilters,
  setQueryLimit,
  perPage,
  isActive
}) => {
  const handleClick = (category) => {
    handleFormData(setFilters, isActive, category)
    setQueryLimit(perPage)
  }

  return (
    <Grid
      item
      onClick={() => {
        handleClick(category)
      }}
    >
      {isActive ? (
        <Button
          disableElevation
          size='small'
          variant='contained'
          color='success'
          sx={{ borderRadius: '10px', fontSize: '10px' }}
        >
          {category.name}
        </Button>
      ) : (
        <Button
          disableElevation
          size='small'
          variant='outlined'
          color='success'
          sx={{ borderRadius: '10px', fontSize: '10px' }}
        >
          {category.name}
        </Button>
      )}
    </Grid>
  )
}

export default MobileButtonFilter
