import * as React from 'react'

function STX(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 120.34 120.34'
      fill='#64e8ba'
      {...props}
    >
      <g data-name='Layer 2'>
        <g data-name='Layer 1'>
          <path d='M27.43 57.62h65.48v-7.67l-19.5-.23 13-20.67H75.96L60.63 53.2 44.38 28.82 33 29.05l13.7 20.9-19.27-.11v7.78zM92.91 62.72H27.43v7.67l19.5.23-13 20.67h10.45l15.33-24.15 16.25 24.38 11.38-.23-13.7-20.9 19.27.11v-7.78z' />
          <path d='M60.17 8A52.17 52.17 0 118 60.17 52.22 52.22 0 0160.17 8m0-8a60.17 60.17 0 1060.17 60.17A60.17 60.17 0 0060.17 0z' />
        </g>
      </g>
    </svg>
  )
}

export default STX
