import * as React from 'react'

const LTC = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 120.34 120.34'
    fill='#64e8ba'
    {...props}
  >
    <g data-name='Layer 2'>
      <g data-name='Layer 1'>
        <path d='M60.17 8A52.17 52.17 0 1 1 8 60.17 52.22 52.22 0 0 1 60.17 8m0-8a60.17 60.17 0 1 0 60.17 60.17A60.17 60.17 0 0 0 60.17 0Z' />
        <path d='m57.57 78.18 4.26-16.14 10.32-3.51 2.64-10.03-10.32 3.56 7.41-28.07H50.67l-9.85 36.22-8.67 2.98-3.2 10.04 9.17-3.12-5.86 21.54h56.43l3.67-13.47H57.57z' />
      </g>
    </g>
  </svg>
)

export default LTC
