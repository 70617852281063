import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { handleFormData } from '../../utilities/sharedfunctions'

const CryptoFilter = ({
  currency,
  isChecked,
  setFilters,
  perPage,
  setQueryLimit
}) => {
  return (
    <Grid
      container
      alignItems='center'
      alignContent='center'
      flexDirection='column'
      item
      xs={2}
      sm={4}
      md={4}
    >
      <Grid item>
        <Checkbox
          color='success'
          checked={isChecked ? true : false}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
          onClick={() => {
            setQueryLimit(perPage)
            handleFormData(setFilters, isChecked, currency)
          }}
          sx={{ color: '#64e8ba' }}
        />
      </Grid>
      <Grid item>
        <Typography
          color='text.primary'
          sx={{
            textAlign: 'center',
            fontSize: { xs: '12px', sm: '14px' },
            whiteSpace: 'nowrap'
          }}
        >
          {currency.name}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CryptoFilter
