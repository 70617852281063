import { useEffect, useContext, useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ButtonFilter from './ButtonFilter'
import Divider from '@mui/material/Divider'
import CryptoFilter from './CryptoFilter'
import Stack from '@mui/material/Stack'
import Venue from './Venue'
import Button from '@mui/material/Button'
import Modal from '../../components/Modal'
import { DataContext } from '../../state/data'
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy
} from 'firebase/firestore'
import { db } from '../../utilities/firebase'

const Home = () => {
  const {
    filters,
    setFilters,
    categories,
    currencies,
    perPage,
    setQueryLimit,
    queryLimit,
    modalState,
    setModalState
  } = useContext(DataContext)
  const [venues, setVenues] = useState(null)
  const [hideButton, setHideButton] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setModalState(false)
    }, 10000)
  }, [setModalState])

  const paginate = async () => {
    setQueryLimit((prevState) => prevState + perPage)
  }

  const fetchCurrencies = async (docId) => {
    const docRef = doc(db, 'currencies', docId)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }

  useEffect(() => {
    const fetchVenueData = async () => {
      const activeFilters = [...filters].filter(
        (selection) => selection?.isActive
      )
      const filtersByType = {}
      for (const { id, type } of activeFilters) {
        if (!filtersByType[type]) filtersByType[type] = []
        filtersByType[type].push(id)
      }

      const collRef = query(collection(db, 'venues'), orderBy('name'))
      const querySnapshot = await getDocs(collRef)
      const collectionData = querySnapshot.docs.map((doc) => {
        return doc.data()
      })

      const filteredVenues = collectionData.filter((obj) =>
        Object.entries(filtersByType).every(([key, arr]) =>
          arr.some((id) => obj[key].includes(id))
        )
      )

      const venueData = await Promise.all(
        filteredVenues.map(async (venue) => {
          return {
            ...venue,
            currencies: await Promise.all(
              venue.currencies.map(
                async (docId) => await fetchCurrencies(docId)
              )
            )
          }
        })
      )
      const totalPages = Math.ceil(venueData.length / queryLimit)

      if (totalPages <= 1) {
        setHideButton(true)
      } else {
        setHideButton(false)
      }
      setVenues([...venueData])
    }
    fetchVenueData()
  }, [filters, queryLimit])

  return (
    <Container
      maxWidth='lg'
      sx={{ minHeight: 'calc(100vh - 80px) !important' }}
    >
      <Grid
        container
        justifyContent='space-between'
        sx={{ minHeight: 'calc(100vh - 80px) !important' }}
      >
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          sx={{
            borderRight: 'solid 1px #132542',
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <Box sx={{ p: '2rem 1rem 0 0' }}>
            <Typography
              variant='h6'
              gutterBottom
              component='div'
              color='text.primary'
              sx={{ fontWeight: 700, mb: '1rem' }}
            >
              Cryptocurrency
            </Typography>
            <Grid
              sx={{ mb: '2rem' }}
              container
              spacing={{ xs: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {currencies.map((currency, index) => {
                const foundCurrency = filters.find(
                  (filter) => filter.id === currency.id
                )
                const isChecked = foundCurrency?.isActive
                return (
                  <CryptoFilter
                    setQueryLimit={setQueryLimit}
                    perPage={perPage}
                    key={index}
                    isChecked={isChecked}
                    currency={currency}
                    setFilters={setFilters}
                  />
                )
              })}
            </Grid>
            <Typography
              variant='h6'
              gutterBottom
              component='div'
              color='text.primary'
              sx={{ fontWeight: 700, mb: '1rem' }}
            >
              Categories
            </Typography>
            <Stack spacing={2}>
              {categories.map((category, index) => {
                const foundCategory = filters.find(
                  (filter) => filter.id === category.id
                )
                const isActive = foundCategory?.isActive

                return (
                  <ButtonFilter
                    key={index}
                    setQueryLimit={setQueryLimit}
                    isActive={isActive}
                    perPage={perPage}
                    category={category}
                    setFilters={setFilters}
                  />
                )
              })}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9}>
          <Box sx={{ p: { xs: '1rem 0 0 0', sm: '2rem 3rem 0 3rem' } }}>
            <Typography
              variant='subtitle2'
              gutterBottom
              component='div'
              color='text.primary'
              sx={{ fontWeight: 700, mb: '1rem', display: { sm: 'none' } }}
            >
              Select one or more coins to get started
            </Typography>
            <Grid
              sx={{ mb: '0.25rem', display: { xs: 'flex', sm: 'none' } }}
              container
              spacing={{ xs: 1 }}
              columns={{ xs: 6 }}
            >
              {currencies.slice(0, 6).map((currency, index) => {
                const foundCurrency = filters.find(
                  (filter) => filter.id === currency.id
                )
                const isChecked = foundCurrency?.isActive
                return (
                  <CryptoFilter
                    setQueryLimit={setQueryLimit}
                    perPage={perPage}
                    key={index}
                    isChecked={isChecked}
                    currency={currency}
                    setFilters={setFilters}
                  />
                )
              })}
              <Divider
                light
                sx={{
                  mt: '1rem',
                  width: '100%',
                  pl: '1rem !important'
                }}
              />
            </Grid>
            <Stack spacing={2}>
              {venues &&
                venues.slice(0, queryLimit).map((venue, index) => {
                  return <Venue key={index} venue={venue} />
                })}
            </Stack>
            <Box sx={{ p: '2.5rem 0' }}>
              {venues && !venues.length && (
                <Typography
                  variant='h6'
                  component='div'
                  color='text.primary'
                  sx={{
                    fontWeight: 700,
                    textAlign: 'center'
                  }}
                >
                  No Results Found
                </Typography>
              )}
              {!hideButton && venues && venues.length > 0 && (
                <Button
                  sx={{ borderRadius: 2, display: 'block', margin: '0 auto' }}
                  disableElevation
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    paginate()
                  }}
                >
                  Load More Locations
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Modal open={modalState} setOpen={setModalState} />
    </Container>
  )
}

export default Home
