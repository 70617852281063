function DOGE(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={290.667}
      height={293.333}
      viewBox='0 0 218 220'
      fill='#64e8ba'
      {...props}
    >
      <path d='M96.3 8.1c-26.8 2.6-52.5 16.6-69.7 38.1C18.9 55.8 10 72.9 7.1 83.9c-15.4 56.4 17.6 112.6 75.3 128.4 7 1.9 10.5 2.2 25.6 2.1 14.7 0 18.8-.4 25.3-2.2 34.7-9.6 61.5-35 72.6-68.8 4.1-12.7 5.5-23.4 4.8-37.8-1.2-26.6-10.8-47.9-30.1-67.2C162.3 20.2 142 10.6 116.1 8c-5.7-.5-10.6-.9-11-.9-.3.1-4.3.5-8.8 1zM123 22.4c64 11.6 95.3 85.1 59.3 139.3-11.1 16.7-28.2 29.4-48 35.7-53.1 16.7-107-17-116.4-72.8-1.5-9-.6-27 1.9-36.1C28.6 56 53.4 31.9 86.5 23.6c3.3-.8 7.4-1.7 9-1.9 6.7-.9 20.4-.6 27.5.7z' />
      <path d='M74 79.9V105H59.9l.3 6.2.3 6.3 6.8.3 6.7.3V168h22.3c24.9 0 32.3-1.1 42.4-6 3.9-2 8.1-5.1 11.6-9 10.6-11.5 14.2-23.3 13.4-44.5-.5-13.9-2-19.7-7.3-29.6-3.4-6.1-11.5-13.9-18-17.2-10.1-5.1-15.2-5.9-40.6-6.4L74 54.8v25.1zm50.1-.8c7.1 3.3 12.4 8.7 15.8 15.8 2.4 5.1 2.6 6.7 2.6 17.6 0 11.1-.2 12.4-2.8 17.7-3.3 6.7-9.1 12-15.6 14.3-2.8 1.1-9.3 1.9-16.8 2.2l-12.3.6V118h23v-13H95V75.8l11.8.4c10 .3 12.5.7 17.3 2.9z' />
    </svg>
  )
}

export default DOGE
